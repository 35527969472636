import { Controller } from '@hotwired/stimulus'
import Sortable from "sortablejs";

export default class extends Controller {
  static values = { url: String }
  static targets = ['id']

  connect() {
    this.sortable = Sortable.create(this.element, {
      handle: ".drag-handle", // specify the drag handle class
      animation: 150,
      onEnd: this.updatePositions.bind(this),
    });
  }

  updatePositions(event) {
    const url = this.urlValue;

    fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      body: JSON.stringify({ ids: this.orderedIds() }),
    });
  }

  orderedIds() {
    return this.idTargets.map(elem => elem.innerText)
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    // Shared fields
    "nameField", "hero_altField", "description_shortField", "slugField", "description_longField",

    // Vacancy fields
    "titleField", "thumbnail_altField", "introduction_textField", "job_descriptionField", "what_you_do_textField", "what_you_get_textField", "what_you_have_textField",

    // Location fields
    "section_header_1Field", "section_text_1Field", "section_header_2Field", "section_text_2Field", "section_header_3Field", "section_text_3Field"
  ]

  connect() {
    this.sourceLocale = this.element.querySelector('#source_locale').value;
  }

  updateSourceLocale(event) {
    this.sourceLocale = event.target.value;
  }

  async translateField(event) {
    const button = event.currentTarget
    const fieldName = button.dataset.field
    const field = this[`${fieldName}FieldTarget`]
    await this.performTranslation(field, button)
  }

  async performTranslation(field, button) {
    const sourceText = field.dataset.sourceText
    
    button.disabled = true
    button.innerHTML = "Translating..."
    
    try {
      const response = await fetch('/admin/vacancy_translator/translate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: JSON.stringify({
          text: sourceText,
          source_lang: this.sourceLocale,
          target_lang: document.querySelector('input[name="target_locale"]').value,
          formality: 'prefer_less'
        })
      })

      const data = await response.json()
      field.value = data.translated_text
    } catch (error) {
      console.error('Translation failed:', error)
    } finally {
      button.disabled = false
      button.innerHTML = "Translate with DeepL"
    }
  }

  async translateAll(event) {
    const context = event.currentTarget.dataset.context;
    const fields = context === 'vacancy' ? ["name", "hero_alt", "slug", "title", "thumbnail_alt", "description_short", "introduction_text", "job_description", "what_you_do_text", "what_you_get_text", "what_you_have_text"] :
      ["name", "slug", "hero_alt", "description_short", "description_long", "section_header_1", "section_text_1", "section_header_2", "section_text_2", "section_header_3", "section_text_3"]

    for (const field of fields) {
      const target = this[`${field}FieldTarget`]
      if (target && !target.value) {  // Only translate empty fields
        const event = { currentTarget: document.querySelector(`button[data-field="${field}"]`) }
        await this.translateField(event)
      }
    }
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];
  static values = { autoUpload: Boolean }

  connect() {
    if (this.autoUploadValue) {
      this.element.addEventListener('change', this.uploadFile.bind(this));
    }
  }

  uploadFile(event) {
    if (this.element.files.length > 0) {
      this.element.form.submit();
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Return if it's the root domain. Reason being, if someone visits without the locale it the url,
    // it will detect and redirect, which will cause the modal to show up again, together with the
    // consent popup.
    if (window.location.hostname === 'yseasonal.com' && window.location.pathname === '/') {
      return;
    }

    let pageViewCount = parseInt(localStorage.getItem('pageViewCount') || '0', 10);
    pageViewCount += 1;
    localStorage.setItem('pageViewCount', pageViewCount);

    // Show the modal only if it's the second page visit and it hasn't been dismissed after 2 seconds
    if (pageViewCount === 2 && !localStorage.getItem('translatedContentModalDismissed')) {
      setTimeout(() => {
        document.getElementById('translated-content-modal').classList.remove('hidden');
      }, 2000); 
    }
  }

  dismiss() {
    localStorage.setItem('translatedContentModalDismissed', 'true');
    document.getElementById('translated-content-modal').classList.add('hidden');
  }
}

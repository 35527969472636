import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { moreText: String, lessText: String };
  static targets = ['container', 'item', 'button'];

  connect() {
    this.showItems();
  }

  showItems() {
    let maxItems = 5;
    this.itemTargets.forEach((item, index) => {
      if (index >= maxItems) {
        item.classList.add('hidden');
      }
    });

    this.buttonTarget.classList.toggle('hidden', this.itemTargets.length <= maxItems);
  }

  toggle(event) {
    event.preventDefault();

    let maxItems = 5;
    const hiddenItems = this.itemTargets.filter((item, index) => index >= maxItems);
    
    hiddenItems.forEach((item) => {
      item.classList.toggle('hidden');
    });
  
    if (hiddenItems.some(item => item.classList.contains('hidden'))) {
      this.buttonTarget.textContent = this.moreTextValue;
    } else {
      this.buttonTarget.textContent = this.lessTextValue;
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field"];

  connect() {
    this.timeout = null;
  }

  search(event) {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (!this.fieldTarget.value.length) {
        // Clear the search when the field is empty
        this.clearResults();
      } else {
        // Submit the form
        this.element.submit();
      }
    }, 650); // Adjust the delay as needed
  }

  clearResults() {
    let urlParts = window.location.pathname.split('/');
    let locale = urlParts[1];
    let vacanciesTranslation = urlParts[2];
    window.location.href = `/${locale}/${vacanciesTranslation}`;
  }
}

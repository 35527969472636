import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    title: String,
    text: String,
    url: String,
  };

  share(event) {
    event.preventDefault();

    navigator
      .share({
        title: this.titleValue || "",
        text: this.textValue || "",
        url: this.urlValue || "",
      })
      .catch((error) => {
        console.error("Error sharing:", error);
      });
  }
}

import { Controller } from "@hotwired/stimulus"
import { Datepicker } from "flowbite-datepicker"

export default class extends Controller {
  static values = {
    options: Object
  }
  
  connect() {
    this.datepicker = new Datepicker(this.element, this.optionsValue)
  }
  
  disconnect() {
    if (this.datepicker) {
      this.datepicker.destroy()
    }
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['button', 'target'];

  connect() {
    this.intersectionObserver = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.buttonTarget.classList.remove('fixed');
          this.buttonTarget.classList.add('w-full');
        } else {
          this.buttonTarget.classList.add('fixed');
          this.buttonTarget.classList.remove('w-full');

        }
      });
    });

    this.intersectionObserver.observe(this.targetTarget);
  }
}

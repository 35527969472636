import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "counter"]

  connect() {
    this.update = this.update.bind(this)
    this.autoGrow = this.autoGrow.bind(this);
    this.update()
    this.autoGrow()
    this.inputTarget.addEventListener("input", this.update)
    this.inputTarget.addEventListener("input", this.autoGrow)
  }

  disconnect() {
    this.inputTarget.removeEventListener("input", this.update)
  }

  update() {
    const currentLength = this.characterCount;
    const maxLength = this.maxLength;
    const minLength = this.minLength;
    let displayText;
    let colorClass;

    if (minLength > 0 && currentLength < minLength) {
      displayText = `-${minLength - currentLength}`;
      colorClass = 'text-red-500';
    } else if (maxLength > 0 && currentLength > maxLength) {
      displayText = `+${currentLength - maxLength}`;
      colorClass = 'text-red-500';
    } else {
      displayText = `${currentLength}`;
      colorClass = 'text-gray-500';
    }

    this.counterTarget.textContent = displayText;
    this.updateColorClass(colorClass);
  }

  updateColorClass(colorClass) {
    this.counterTarget.classList.remove('text-red-500', 'text-gray-500');
    this.counterTarget.classList.add(colorClass);
  }

  autoGrow() {
    this.inputTarget.style.height = 'auto';
    this.inputTarget.style.height = this.inputTarget.scrollHeight + 'px';
  }

  get characterCount() {
    return this.inputTarget.value.length
  }

  get maxLength() {
    return this.inputTarget.maxLength || 0
  }

  get minLength() {
    return this.inputTarget.minLength || 0
  }
}

import '@hotwired/turbo-rails'
import * as ActiveStorage from '@rails/activestorage'
import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import 'flowbite'
import 'flowbite-datepicker'
import 'flowbite/dist/flowbite.turbo.js'
import 'flag-icons/css/flag-icons.min.css';

// Register all controllers in the controllers folder
const application = Application.start()
const controllers = import.meta.glob('../controllers/*_controller.js', { eager: true })
registerControllers(application, controllers)

Turbo.start()
ActiveStorage.start()

document.addEventListener('turbo:load', function() {
  initFlowbite();
});

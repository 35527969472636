import { Controller } from '@hotwired/stimulus'
import UAParser from 'ua-parser-js'

export default class extends Controller {
  static targets = ["form", "deviceType", "browser", "screenWidth", "screenHeight", "windowWidth", "windowHeight"]

  connect() {
    this.setClientInfo()
    this.formTarget.addEventListener("submit", this.resetForm.bind(this))
  }

  setClientInfo() {
    const parser = new UAParser()
    const result = parser.getResult()
    
    const deviceType = result.device.type || "desktop"
    const browser = `${result.browser.name} ${result.browser.version}`
    const screenWidth = window.screen.width
    const screenHeight = window.screen.height
    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight

    this.deviceTypeTarget.value = deviceType
    this.browserTarget.value = browser
    this.screenWidthTarget.value = screenWidth
    this.screenHeightTarget.value = screenHeight
    this.windowWidthTarget.value = windowWidth
    this.windowHeightTarget.value = windowHeight
  }

  resetForm(event) {
    // Allow the form to submit normally
    setTimeout(() => {
      this.formTarget.reset()
    }, 0)
  }
}

import { Controller } from "@hotwired/stimulus"
import { Dismiss } from "flowbite";

export default class extends Controller {
  connect() {
    initFlowbite();
    this.toastDismiss = new Dismiss(this.element);

    setTimeout(() => {
      if (this.toastDismiss) {
        this.toastDismiss.hide();
      }
    }, 5000);
  }
}
